import gql from 'graphql-tag'


export const DETAIL_BOARD = (templateType) => gql`query DETAIL_BOARD ($deviceType: String!, $deviceId: Int!) {
  board: detail${templateType}Board (deviceType: $deviceType, deviceId: $deviceId) {
    id name lists {
      id name cards {
        id name
      }
    }
  }
}`

export const CREATE_CARD = (templateType) => gql`mutation CREATE_CARD ($deviceType: String!, $deviceId: Int!, $listId: String!, $input: ${templateType}CardInput!) {
  createCard: create${templateType}Card (deviceType: $deviceType, deviceId: $deviceId, listId: $listId, input: $input)
}`

export const DESTROY_CARD = (templateType) => gql`mutation DESTROY_CARD ($deviceType: String!, $deviceId: Int!, $cardId: String!) {
  destroyCard: destroy${templateType}Card (deviceType: $deviceType, deviceId: $deviceId, cardId: $cardId)
}`
