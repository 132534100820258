<template>
  <span class="">
    <span
      class="text-success pointer px-1"
      @click="toggle = !toggle">
      <fa icon="plus"></fa>
    </span>

    <b-modal
      v-model="toggle"
      :title="title"
      hide-footer>
      <div class="form-row">
        <sgv-input-text
          class="col-6"
          label="หัวข้อ"
          v-model="formData.name"
          :validations="[
            {text: 'required!', value: $v.formData.name.$dirty && !$v.formData.name.required}
          ]">
        </sgv-input-text>

        <sgv-input-datetime
          class="col-6"
          label="ครบกำหนดวันที่"
          v-model="formData.dueAt">
        </sgv-input-datetime>

        <sgv-input-textarea
          class="col-12"
          label="รายละเอียดเพิ่มเติม"
          autoGrow
          v-model="formData.description">
        </sgv-input-textarea>
      </div>

      <button
        type="button"
        class="btn btn-success mr-2"
        @click="addCard">
        เพิ่ม
      </button>

      <button
        type="button"
        class="btn btn-primary"
        @click="toggle = false">
        กลับ
      </button>
    </b-modal>
  </span>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import { CREATE_CARD } from './graph'

export default {
  props: {
    deviceId: {
      type: Number,
      required: true
    },
    deviceType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    listId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      toggle: false,
      formData: {
        name: '',
        description: '',
        dueAt: null
      }
    }
  },
  validations: {
    formData: {
      name: { required }
    }
  },
  methods: {
    serializeInput (input) {
      return {
        ...input,
        dueAt: this.$date.format(input.dueAt).raw
      }
    },
    addCard () {
      const input = this.serializeInput(this.formData)

      this.$apollo.mutate({
        mutation: CREATE_CARD(this.templateType),
        variables: {
          deviceType: this.deviceType,
          deviceId: this.deviceId,
          listId: this.listId,
          input
        }
      })
      .then(() => {
        this.$toasted.global.success('เพิ่มสำเร็จ')
        this.$emit('updated', null)
        this.toggle = false
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    }
  }
}
</script>

<style lang="css" scoped>
</style>
