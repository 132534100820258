import gql from 'graphql-tag'


const listResponse = `
  id type code name isActive
`

export const LIST_DEVICE = (templateType) => gql`query LIST_DEVICE ($deviceType: String!, $q: FilterInput) {
  devices: list${templateType} (deviceType: $deviceType, q: $q) {${listResponse}}
}`

const detailResponse = `
  id type code name isActive
  boardId trelloKey trelloToken
`

export const DETAIL_DEVICE = (templateType) => gql`query DETAIL_DEVICE ($deviceType: String!, $deviceId: Int!) {
  device: detail${templateType} (deviceType: $deviceType, deviceId: $deviceId) {${detailResponse}}
}`

export const CREATE_DEVICE = (templateType) => gql`mutation CREATE_DEVICE ($deviceType: String!, $input: ${templateType}Input!) {
  createDevice: create${templateType} (deviceType: $deviceType, input: $input) {${detailResponse}}
}`

export const UPDATE_DEVICE = (templateType) => gql`mutation UPDATE_DEVICE ($deviceType: String!, $deviceId: Int!, $input: ${templateType}Input!) {
  updateDevice: update${templateType} (deviceType: $deviceType, deviceId: $deviceId, input: $input) {${detailResponse}}
}`

export const DESTROY_DEVICE = (templateType) => gql`mutation DESTROY_DEVICE ($deviceType: String!, $deviceId: Int!) {
  destroyDevice: destroy${templateType} (deviceType: $deviceType, deviceId: $deviceId) {id}
}`
