var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('div',{staticClass:"form-row"},[_c('sgv-input-text',{staticClass:"col-6",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"รหัส","validations":[
        {text: 'required!', value: _vm.v.formData.code.$dirty && !_vm.v.formData.code.required}
      ]},model:{value:(_vm.formData.code),callback:function ($$v) {_vm.$set(_vm.formData, "code", $$v)},expression:"formData.code"}}),_c('sgv-input-text',{staticClass:"col-6",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"ชื่อ","validations":[
        {text: 'required!', value: _vm.v.formData.name.$dirty && !_vm.v.formData.name.required}
      ]},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}}),_c('sgv-input-text',{staticClass:"col-6",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"Board ID","validations":[
        {text: 'required!', value: _vm.v.formData.boardId.$dirty && !_vm.v.formData.boardId.required} ]},model:{value:(_vm.formData.boardId),callback:function ($$v) {_vm.$set(_vm.formData, "boardId", $$v)},expression:"formData.boardId"}}),_c('sgv-input-text',{staticClass:"col-6",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"Trello Key","validations":[
        {text: 'required!', value: _vm.v.formData.trelloKey.$dirty && !_vm.v.formData.trelloKey.required} ]},model:{value:(_vm.formData.trelloKey),callback:function ($$v) {_vm.$set(_vm.formData, "trelloKey", $$v)},expression:"formData.trelloKey"}}),_c('sgv-input-text',{staticClass:"col-12",attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"Trello Token","validations":[
        {text: 'required!', value: _vm.v.formData.trelloToken.$dirty && !_vm.v.formData.trelloToken.required} ]},model:{value:(_vm.formData.trelloToken),callback:function ($$v) {_vm.$set(_vm.formData, "trelloToken", $$v)},expression:"formData.trelloToken"}})],1),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-12"},[_c('sgv-input-check',{attrs:{"disabled":_vm.$auth.disabled(_vm.method),"label":"ใช้งาน","inline":""},model:{value:(_vm.formData.isActive),callback:function ($$v) {_vm.$set(_vm.formData, "isActive", $$v)},expression:"formData.isActive"}})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }