<template>
  <div class="">
    <button
      type="button"
      class="btn btn-warning"
      @click="fetchBoard">
      Fetch Board
    </button>

    <div class="mt-3" v-if="board">
      <div class="">ID: {{board.id}}</div>
      <div class="">Name: {{board.name}}</div>
      <ul>
        <li v-for="l in board.lists" :key="l.id">
          {{l.name}}
          <DetailBoardCardFormModal
            :templateType="templateType"
            :deviceType="deviceType"
            :deviceId="deviceId"
            :title="l.name"
            :listId="l.id"
            @updated="fetchBoard">
          </DetailBoardCardFormModal>
          <ul>
            <li v-for="c in l.cards" :key="c.id">
              {{c.name}}
              <span
                class="px-1 text-danger pointer"
                @dblclick="destroyCard(c.id)">
                <fa icon="trash"></fa>
              </span>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import DetailBoardCardFormModal from './DetailBoardCardFormModal.vue'
import {
  DETAIL_BOARD,
  DESTROY_CARD
} from './graph'

export default {
  components: {
    DetailBoardCardFormModal
  },
  props: {
    deviceId: {
      type: Number,
      required: true
    },
    deviceType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
  },
  data () {
    return {
      board: null
    }
  },
  methods: {
    fetchBoard () {
      this.$apollo.query({
        query: DETAIL_BOARD(this.templateType),
        variables: {
          deviceType: this.deviceType,
          deviceId: this.deviceId
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        this.board = res.data.board
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    destroyCard (cardId) {
      this.$apollo.mutate({
        mutation: DESTROY_CARD(this.templateType),
        variables: {
          deviceType: this.deviceType,
          deviceId: this.deviceId,
          cardId
        },
      })
      .then(() => {
        this.$toasted.global.success('ลบสำเร็จ')
        this.fetchBoard()
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    }
  }
}
</script>

<style lang="css" scoped>
</style>
